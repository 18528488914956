import React from "react";
import { Grid } from "@material-ui/core";
import { Card } from "../styledComponents/cardStyles";
import { Assignment, PeopleAlt, Store, LocalOffer } from "@material-ui/icons";

const countValue = {
  margin: "0 0 7px",
  color: " #555",
  fontSize: "1.7rem",
  fontWeight: "400",
};

const countSubTitle = {
  margin: "0px",
  fontSize: "0.9rem",
  paddingTop: "20px",
  color: "#777",
};
const countTitle = {
  color: "#a2a2a2",
};

function GDashboardCounts(props) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} style={{ padding: "2px 8px" }}>
      <Card>
        {props.type === "user" ? <PeopleAlt fontSize="large" /> : ""}
        {props.type === "store" ? (
          <Store fontSize="large" style={{ background: "#e46d8f" }} />
        ) : (
          ""
        )}
        {props.type === "order" ? (
          <Assignment fontSize="large" style={{ background: "#46ca7c" }} />
        ) : (
          ""
        )}
        {props.type === "offer" ? <LocalOffer fontSize="large" /> : ""}
        <div
          style={{
            display: "inline-block",
            float: "right",
            textAlign: "right",
          }}
        >
          <small style={countTitle}>{props.title}</small>
          <h2 style={countValue}>{props.total_count}</h2>
        </div>

        <p style={countSubTitle}>{props.subtitle}</p>
      </Card>
    </Grid>
  );
}

export default GDashboardCounts;
