import React from "react";

const ProgressBar = (props) => {
  const { completed } = props;
  const containerStyles = {
    width: "70%",
    backgroundColor: "#cbcbcb",
    borderRadius: 0,
    display: "inline-block",
  };

  const fillerStyles = {
    height: "5px",
    width: `${completed}%`,
    backgroundColor: "#8f8f8f",
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };
  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}></span>
      </div>
    </div>
  );
};

export default ProgressBar;
