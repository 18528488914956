import React from "react";
import styled from "styled-components";
import GButton from "./gButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const ConfirmDialog = styled.div`
  background: #fff;

  .g-dialog {
    background: #fff;

    &.MuiSkeleton-rect {
      margin-top: 0px;
      margin-bottom: 25px;
    }
    &.MuiSkeleton-text {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }
`;

function GConfirmDialog(props) {
  return (
    <ConfirmDialog>
      <div>
        <Dialog
          fullWidth={true}
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{props.text}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <GButton onClick={props.handleClose} variant="text">
              {props.handleCloseText ? props.handleCloseText : 'Cancel'}
            </GButton>
            {props.onConfirmClose ? <GButton onClick={props.onConfirmClose} variant="text">
              Confirm
            </GButton> : ''}
          </DialogActions>
        </Dialog>
      </div>
    </ConfirmDialog>
  );
}

export default GConfirmDialog;
