import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Ul = styled.ul`
  display: inline-flex;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0;
  list-style-type: none;
`;
const Li = styled.li`
  padding: 15px 10px;
  text-align: left;
  font-size: 14px;
  position: relative;
  display: block;
  float: left;
  cursor: pointer;

  h4 {
    margin: 5px 0;
    font-size: 18px;
  }
  p {
    margin: 5px 0;
  }
`;

export default function orderStatuses(props) {
  console.log(props);
  // const classes = useStyles();
  function handleClick(iden) {
    // event.preventDefault();
    console.info("You clicked a breadcrumb.");
    props.onClickStatus(iden);
  }

  return (
    <div>
      <Ul>
        {props.statusList.map((status) => {
          return (
            <Li
              onClick={() => {
                handleClick(status.identifier);
              }}
              key={status.identifier}
              style={{
                color: "#" + status.color1,
                background: "#" + status.color2,
                borderBottom:
                  props.defaultStatus === status.identifier
                    ? "4px solid #" + status.color1
                    : "",
                width: "18%",
              }}
              className={(props.class, status.color)}
            >
              <h4>
                {props.currencySymbol}
                {status.amount}
              </h4>
              <p>
                {status.count} {status.title} Orders
              </p>
            </Li>
          );
        })}
      </Ul>
    </div>
  );
}
