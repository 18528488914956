import React, { useState } from "react";
import { ImgUrl } from "../services";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

export default function GLightBox(props) {
  const [photoIndex, setPhotoIndex] = useState(props.selectedIndex || 0);
  const [images, setImages] = useState([props.images] || []);
  console.log(images);
  return (
    <div>
      {images.length > 0 ? (
        <Lightbox
          mainSrc={ImgUrl(props.imgType) + "/" + images[photoIndex]}
          nextSrc={
            images.length > 1
              ? ImgUrl(props.imgType) +
              "/" +
              images[(photoIndex + 1) % images.length]
              : ""
          }
          prevSrc={
            images.length > 1
              ? ImgUrl(props.imgType) +
              "/" +
              images[(photoIndex + images.length - 1) % images.length]
              : ""
          }
          onCloseRequest={() => props.setImgPreview(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      ) : (
          ""
        )}
    </div>
  );
}
