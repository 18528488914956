import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import GButton from "./gButton";

const PaginationSelect = styled.div`
  text-align: right;
  display: inline-block;
  select {
    background: #fff;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    outline: 0;
    padding: 5px;
    border-radius: 20px;
  }
`;

export default function GPagination(props) {
  const [selectedValue, setSelectedValue] = useState(props.defaultvalue);
  const [optionsList, setOptionsList] = useState([]);
  const [count, setCount] = useState(0);
  console.log(props);
  useEffect(() => {
    setSelectedValue(props.defaultvalue);

    if (count < props.totalPage) {
      let counter = count + 1;
      setCount(counter);
      optionsList.push(counter);
    }
  }, [props, count]);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    props.onChangePage(e.target.value);
  };

  var pOptionsList = optionsList.filter((o) => o <= props.totalPage).map((option, i) => {
    return (
      <option key={i} value={option}>
        {option}
      </option>
    );
  });

  return (
    <PaginationSelect>
      {optionsList.length > 1 ? (
        <select
          id="pagination"
          className={props.display}
          value={selectedValue}
          // defaultValue={selectedValue} // set selected value
          onChange={handleChange} // assign onChange function
          style={props.style}
        >
          {pOptionsList}
        </select>
      ) : (
          ""
        )}
    </PaginationSelect>
  );
}
