import React, { useState, useEffect } from "react";
// import { useIsFocused } from "@react-navigation/native";

const useLocalStorage = (key, initialValue = "", refresh = false) => {
  const [storedValue, setStoredValue] = useState(initialValue);
  //   const isFocused = useIsFocused();

  const getStoredItem = async (key, initialValue) => {
    try {
      const item = window.localStorage.getItem(key);
      const value = item ? JSON.parse(item) : initialValue;
      setStoredValue(value);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStoredItem(key, initialValue);
  }, [refresh]);

  const setValue = async (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
