import React, { useEffect, useState, useRef } from "react";
import GText from "./gText";
import styled from "styled-components";
import { useDetectOutsideClick } from "../useDetectOutsideClick";

const Popover = styled.div`
  position: absolute;
  text-align: left;
  z-index: 9;

  &.left {
    left: 0;
  }
  &.right {
    left: -220px;
    // bottom: -30px;
  }
  &.bottom {
    bottom: 0;
  }
  &.top {
    top: 0;
  }
`;

const Div = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  padding: 5px 15px;

  svg {
    fill: #aaa !important;
    font-size: 1.5rem !important;
    margin-right: 10px;

    path {
      fill: #aaa !important;
    }
  }
  &:last-child {
    border: 0;
  }

  &:hover {
    background: #f5f5f5;
    cursor: pointer;
  }
`;

const GPopover = (props) => {
  console.log(props);
  const { onPress, show, placement } = props;
  const [menuArray, setMenuArray] = useState(props.buttons || []);
  const wrapperSRef = useRef(null);
  const [showPopover, setShowPopover] = useDetectOutsideClick(
    wrapperSRef,
    show
  );

  useEffect(() => {
    setShowPopover(show);
  }, [show]);

  useEffect(() => {
    console.log(showPopover);
    if (showPopover == false) {
      onPress();
    }
  }, [showPopover]);

  useEffect(() => {
    setMenuArray(props.buttons || []);
  }, [props]);
  // const [dialogOpen, setDialogOpen] = useState(true);

  return (
    <Popover className={placement} ref={wrapperSRef}>
      {showPopover == true && (
        <AdditionalButtons
          onChoose={() => onPress()}
          buttons={menuArray}
          setShowPopover={setShowPopover}
        />
      )}
    </Popover>
  );
};

export default GPopover;

const AdditionalButtons = ({ buttons, onChoose, setShowPopover }) => {
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        width: "220px",
        borderRadius: "4px",
      }}
    >
      {buttons
        .filter((b) => b.show == true)
        .map((b, i) => (
          <Div key={i}>
            {b.icon}
            <GText
              text={b.title}
              med
              style={{
                padding: "2px",
                margin: "8px 0",
              }}
              onClick={(e) => {
                console.log(e);
                e.stopPropagation();
                setShowPopover(false);
                b.action();
                onChoose();
              }}
            />
          </Div>
        ))}
    </div>
  );
};
