import React, { useState, useEffect } from "react";
// import { Button} from '@material-ui/core';
import styled from "styled-components";

const Selectbox = styled.select`
  background: #fff;
  padding: 0.6em;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0px;
  width: fit-content;
  box-shadow: none;
  outline: 0;
  border: 1px solid #ccc;
  color: #555;
  font-size: 0.95rem;

  &.block {
    display: block;
  }

  &.inline {
    display: inline-block;
  }
`;

function GSelectbox(props) {
  const [selectedValue, setSelectedValue] = useState(props.defaultvalue);

  useEffect(() => {
    setSelectedValue(props.defaultvalue);
  }, [props]);
  // handle onChange event of the dropdown
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    props.onChange(e.target.value);
  };

  var optionsList = props.data.map((option, i) => {
    return (
      <option key={i} value={option[props.option_value]}>
        {option[props.option_key]}
      </option>
    );
  });

  var optionsGroupList = props.data.map((option, ind) => {
    return (
      <optgroup
        label={option[props.optTitle ? props.optTitle : props.option_key]}
        key={ind}
      >
        {option.subcategories
          ? option.subcategories.map((o, i) => {
              return (
                <option key={i} value={o[props.option_value]}>
                  {o[props.option_key]}
                </option>
              );
            })
          : option.data
          ? option.data.map((o, i) => {
              return (
                <option key={i} value={o[props.option_value]}>
                  {o[props.option_key]}
                </option>
              );
            })
          : ""}
      </optgroup>
    );
  });

  return (
    <React.Fragment>
      <Selectbox
        id={props.id}
        className={props.display}
        placeholder={props.placeholder}
        value={selectedValue}
        // defaultValue={selectedValue} // set selected value
        onChange={handleChange} // assign onChange function
        style={props.style}
      >
        {props.showSelectOption === false ? (
          ""
        ) : (
          <option value="">{props.placeholder}</option>
        )}
        {props.optGroup === true ? optionsGroupList : optionsList}
      </Selectbox>
    </React.Fragment>
  );
}

export default GSelectbox;
