import React, { useEffect } from "react";
import "../css/loadingBar.css";

export var LoadingBar = ({ width, percent, style }) => {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setValue(percent * width);
  }, [percent, width]);

  return (
    <div>
      <div
        className="progress-div"
        style={Object.assign({}, style, { width: width })}
      >
        <div style={{ width: `${value}%` }} className="progress" />
      </div>
    </div>
  );
};
