import React, { useState, useEffect } from "react";
// import { Button} from '@material-ui/core';
import styled from "styled-components";
import sortAsc from "../assets/images/sort-ascending.svg";
import sortDesc from "../assets/images/sort-descending.svg";
const Sortingbox = styled.select`
  background: #fff;
  padding: 0.6em;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0px;
  width: fit-content;
  box-shadow: none;
  outline: 0;
  border: 1px solid #ccc;
  color: #555;
  font-size: 0.95rem;

  &.block {
    display: block;
  }

  &.inline {
    display: inline-block;
  }
`;
const SortOrderbox = styled.div`
  background: #fff;
  padding: 0.63em;
  font-size: 1rem;
  font-weight: 500;display:inline-block;
  border-radius: 0px;
  width: fit-content;
  box-shadow: none;
  outline: 0;
  border: 1px solid #ccc;border-left:0;vertical-align:top;
  color: #555;
  font-size: 0.95rem;
 
 & img{width:18px;vertical-align:middle}
  
`;

function GSorting(props) {
  const [selectedValue, setSelectedValue] = useState(props.defaultvalue);
  const [selectedOrderValue, setSelectedOrderValue] = useState(props.defaultOrdervalue);

  useEffect(() => {
    setSelectedValue(props.defaultvalue);
    setSelectedOrderValue(props.defaultOrdervalue)
  }, [props]);

  // handle onChange event of the dropdown
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    props.onChange(e.target.value);
  };

  var optionsList = props.data.map((option, i) => {
    return (
      <option key={i} value={option[props.option_value]}>
        {option[props.option_key]}
      </option>
    );
  });



  return (
    <div style={Object.assign({}, { display: 'inline-block' }, props.style)}>
      <Sortingbox
        id={props.id}
        className={props.display}
        placeholder={props.placeholder}
        value={selectedValue}
        // defaultValue={selectedValue} // set selected value
        onChange={handleChange} // assign onChange function

      >
        {optionsList}
      </Sortingbox>


      <SortOrderbox
        id={props.id}
        className={props.display}
      >
        {selectedOrderValue === 'ASC' ?
          <img src={sortAsc} alt="" onClick={() => {
            setSelectedOrderValue('DESC');
            props.onOrderChange('DESC')
          }} />
          :
          <img src={sortDesc} alt="" onClick={() => {
            setSelectedOrderValue('ASC');
            props.onOrderChange('ASC')
          }} />
        }
      </SortOrderbox>

    </div>
  );
}

export default GSorting;
