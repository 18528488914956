import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";

const radioLabelStyle = {
  display: "inline-block",
  paddingRight: "15px",
};
function checkBoxes(props) {
  const { label, name, key_title, key_value, options, ...rest } = props;
  return (
    <div className="form-control">
      <label>{label}</label>
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option, index) => {
            return (
              <div key={index} style={{ display: "inline-block" }}>
                <input
                  type="checkbox"
                  id={option[key_value]}
                  {...field}
                  value={option[key_value]}
                  checked={field.value.includes(option[key_value])}
                />
                <label style={radioLabelStyle} htmlFor={option[key_value]}>
                  {option.key}
                </label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default checkBoxes;
