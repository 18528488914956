import React from "react";
import styled from "styled-components";
import { Search } from "@material-ui/icons";

const Input = styled.input`
  width: 60%;
  /* min-width: 350px; */
  max-width: 450px;
  margin: 10px 0 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #e2e2e2;
  padding-left: 10px;
`;

export const Filter = ({
  value,
  handleChange,
  handleBlur,
  handleFocus,
  placeholder,
}) => {
  return (
    <React.Fragment>
      <Search
        fontSize="small"
        style={{ verticalAlign: "middle", fontSize: "22px", color: "#ccc" }}
      />
      <Input
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
      />
    </React.Fragment>
  );
};
