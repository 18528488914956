function Query(location, additional = {}) {
  let search = location.search;

  if (search.charAt(0) != "?" && additional === {}) {
    return {};
  }
  let params = search.substring(1).split("&");
  let obj = {};
  params.map((s) => {
    if (!s.includes("=")) {
      return;
    }
    let key = s.split("=")[0];
    let value = s.split("=")[1];
    obj[key] = value.charAt(0) == "[" ? JSON.parse(value) : value;
  });

  Object.keys(additional).forEach((k) => (obj[k] = additional[k]));
  return obj;
}

function QueryString(obj) {
  let t = [];
  Object.keys(obj).forEach((k) => {
    console.log("obj==" + obj[k]);
    if (obj[k] == null || obj[k] === "") {
      return;
    }
    t.push(
      `${k}=${obj[k].constructor === Array ? JSON.stringify(obj[k]) : obj[k]}`
    );
  });
  return "?" + t.join("&");
}

export default function QueryWith(location, additional) {
  let t = Query(location, additional);
  console.log("t---" + JSON.stringify(t));
  return [QueryString(t), location.pathname + QueryString(t)];
}

//export default Query;
